/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { SanityImage } from '@components/sanity/sanityImage';
import SimplePortableText from '@components/sanity/simplePortableText';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line
import Layout from '@components/layout';
import { useArticleQuery } from '@querys/useArticleQuery';

import ArrowButton from '@components/UI/arrowButton';

const Article = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;
  const { title, slug, author, intro, body, date, featuredImage } = page || {};

  const { href } = useLocation();

  const { articles } = useArticleQuery();

  // filter out current article and slice length to 2
  const relatedArticles = articles
    ?.filter((article) => article?.slug?.current !== slug?.current)
    ?.slice(0, 2);

  const shareStyle = {
    display: 'inline-block',
    textDecoration: 'underline',
    textUnderlineOffset: '3px',
  };

  const [hover, setHover] = useState(false);

  return (
    <Layout reversedHeader={true} disabledHeader={true}>
      <div className='px-gutter lg:px-0 lg:grid grid-cols-14 pt-[120px] md:pt-[170px] lg:pt-[200px]'>
        <div className='col-start-2 col-end-7 row-start-1 lg:sticky lg:top-0 lg:self-start pt-[21px] pb-[21px] pr-5'>
          <h3 className='blockH2 '>{title}</h3>
          <div className='blockH5 text-grey mt-12 '>
            <h4>Date</h4>
            <h4>{date}</h4>
          </div>
          {author && (
            <div className='blockH5 text-grey mt-12 '>
              <h4>Author</h4>
              <h4>{author}</h4>
            </div>
          )}
          <div className='text-grey mt-[39px]'>
            <h4 className='blockH5'>Share</h4>
            <div className='blockH5 mt-1 flex space-x-4'>
              {' '}
              <FacebookShareButton url={href} style={shareStyle}>
                Facebook
              </FacebookShareButton>
              <TwitterShareButton url={href} style={shareStyle}>
                X
              </TwitterShareButton>
              <LinkedinShareButton url={href} style={shareStyle}>
                LinkedIn
              </LinkedinShareButton>
              <EmailShareButton url={href} style={shareStyle}>
                Email
              </EmailShareButton>
            </div>
          </div>
        </div>
        <div className='col-start-7 col-end-14 row-start-1 mt-10 lg:mt-0'>
          <div className='max-w-full h-auto relative rounded-[30px] overflow-hidden'>
            <SanityImage
              image={featuredImage}
              className=' top-0 left-0 w-full h-full object-cover'
            />
          </div>
          <div className='mt-10 xl:mt-[103px] text-grey'>
            {intro && <h3 className='richH2'>{intro}</h3>}
            <SimplePortableText className='pt-[32px]' text={body} />
          </div>
        </div>
      </div>
      <div className='px-gutter pt-space-m'>
        <h2 className='blockH2'>Similar Posts</h2>
        <div className='pt-space-s flex flex-col space-y-[100px]'>
          {relatedArticles?.map((article) => (
            <Link
              to={`/news/${article?.slug?.current}`}
              className='flex-col lg:grid grid-cols-10 gap-x-[58px] '
            >
              <h4 className='blockH6 text-grey col-start-1 col-end-3'>
                {article?.date}
              </h4>
              <div className='col-start-3 col-end-6 '>
                <h2 className='blockH4'>{article?.title}</h2>
                <p className='line-clamp-5 blockH6 text-grey pt-5'>
                  {article?.intro}
                </p>
                <Link
                  to={`/news/${article?.slug?.current}`}
                  className='blockH5 inline-block mt-[25px]'
                >
                  {' '}
                  <ArrowButton linkText='Read more' imageHover={hover} />
                </Link>
              </div>
              <div
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className='rounded-[50px] col-start-6 col-end-11 aspect-w-4 aspect-h-3 relative overflow-hidden mr-10 mt-10 lg:mt-0'
              >
                <SanityImage
                  image={article?.featuredImage}
                  className='absolute inset-0 w-full h-full object-cover'
                />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Article;

export const articleQuery = graphql`
  query standardArticleQuery($slug: String!) {
    page: sanityArticle(slug: { current: { eq: $slug } }) {
      author
      body: _rawBody(resolveReferences: { maxDepth: 10 })
      date(formatString: "DD MMMM YYYY")
      featuredImage {
        ...ImageWithPreview
      }
      title
      slug {
        current
      }
      intro
    }
  }
`;
